<!--
 * @Description: In User Settings Edit
 * @Author: your name
 * @Date: 2019-09-10 09:43:36
 * @LastEditTime: 2019-09-29 14:51:20
 * @LastEditors: Please set LastEditors
 -->
<template>
  <div class="figuresix" v-if="you_xiaoping">
        <div class="xiaoping">
            <div class="xuanze_nianfen">
                <p>
                    年份:
                    <el-select v-model="time" @change='xuanze_nianfen_biao'>
                        <el-option
                            v-for="(i,index) in year_list"
                            :key="index"
                            :label="i"
                            :value="i">
                        </el-option>
                    </el-select>
                </p>
                <div>
                    <p @click="dianji_xuanze(index)" v-for="(i,index) in san_xuanyi" :key='index'><img :src="xuanze==index?require('../../assets/huang_shixin.png'):require('../../assets/ccc_quan.png')" alt="">{{i}}</p>
                </div>
                <p class="xuanze_nianfen_right"><span></span>&nbsp;&nbsp;目标销售额&nbsp;&nbsp;&nbsp;&nbsp;<span></span>&nbsp;&nbsp;利润点</p>
            </div>
            <img v-if="time==''" style="margin-top:0.1rem;height:86%;width:100%;" src="../../assets/shili_tu6.png" alt="">
            <div v-if="time!=''" id="myCharts10" :style="{width:'80%',height:'80%',background:'#fff'}"></div>
        </div>
        <div class="quanping" v-if="quan_zia">    <!--v-if="quan_zia==1"-->
            <div class="box">
                <div class="guanbi" @click="dianji_guanbi">X</div>
                <p class="box_text">销售目标进度（六）</p>
                <div class="box_con">
                    <div class="xuanze_nianfen">
                        <p>
                            年份:
                            <el-select v-model="time" @change='xuanze_nianfen_biao'>
                                <el-option
                                    v-for="(i,index) in year_list"
                                    :key="index"
                                    :label="i"
                                    :value="i">
                                </el-option>
                            </el-select>
                        </p>
                        <div>
                            <p @click="dianji_xuanze(index)" v-for="(i,index) in san_xuanyi" :key='index'><img :src="xuanze==index?require('../../assets/huang_shixin.png'):require('../../assets/ccc_quan.png')" alt="">{{i}}</p>
                        </div>
                        <p class="xuanze_nianfen_right"><span></span>&nbsp;&nbsp;目标销售额&nbsp;&nbsp;&nbsp;&nbsp;<span></span>&nbsp;&nbsp;利润点</p>
                    </div>
                    <div v-if="time!=''" id="myCharts10_da" :style="{width:'80%',height:'80%',background:'#fff'}"></div>
                </div>
            </div>
        </div>
  </div> 
</template>

<script>
import { generate_as_aim_plan, query_as_year_list } from '../../api/api.js'
export default {
  name: 'figuresix',
  data () {
    return {
      time: '',
      year_list: [],
      san_xuanyi: ['部门目标', '产品目标', '人员目标'],
      xuanze: 0,
      quan_zia: false,
      tupian: {
        x_zhou: [],
        zhi: [],
        zhexian: []
      },
      you_xiaoping: true
    }
  },
  computed: {
  },
  props: {
    //   tuchu_chuanshu:String,
    //   tu6_quanzi:String,
    //   required: true,
  },
  watch: {
    //   tu6_quanzi(val){
    //     console.log(val)
    //     this.quan_zia=val
    // },
  },
  created () {
    this.jichu()
  },
  mounted () {
  },
  methods: {
    //   基础信息
    jichu () {
      query_as_year_list({
        data: {
          ent_id: this.$ent_id(),
          aim_type: '6'
        }
      }).then(res => {
        console.log(res)
        if (res.data.code == 10237) {
          const date = JSON.parse(res.data.body.data)
          console.log(date)
          if (date != []) {
            this.year_list = []
            for (let i = 0; i < date.length; i++) {
              this.year_list.push(date[i] + '年')
            }
          }
          date.forEach(it => {
            if (it == new Date().getFullYear()) {
              this.time = new Date().getFullYear() + '年'
              this.xuanze_nianfen_biao()
            }
          })
        } else if (res.data.code == 10238) {}
      })
    },
    // 选择年份
    xuanze_nianfen_biao () {
      console.log(this.time)
      this.xuanze = 0
      this.$emit('tu6_nianfen', this.time)
      this.chaxun_jiekou()
      // this.chanpin_liebiao_jiekou()
    },
    // 计算
    jisuan () {
    },
    chaxun_jiekou () {
      generate_as_aim_plan({
        data: {
          ent_id: this.$ent_id(),
          year: this.time.slice(0, 4)
        }
      }).then(res => {
        console.log('chaxun_jiekouchaxun_jiekou', res)
        if (res.data.code == 200) {
          const bumen = JSON.parse(res.data.body.dept_list)
          const chanpin = JSON.parse(res.data.body.prod_list)
          const renyuan = JSON.parse(res.data.body.staff_list)
          console.log(bumen)
          console.log(chanpin)
          console.log(renyuan)
          this.bumen_list = bumen
          this.chanpin_list = chanpin
          this.renyuan_list = renyuan
          this.tupian.x_zhou = []
          this.tupian.zhi = []
          this.tupian.zhexian = []
          for (let i = 0; i < bumen.length; i++) {
            this.tupian.x_zhou.push(bumen[i].month)
            this.tupian.zhi.push(Number(bumen[i].amt_aim).toFixed(0))
            this.tupian.zhexian.push(Number(bumen[i].amt_breakeven).toFixed(0))
          }
          setTimeout(() => {
            this.tupian10()
            if (this.quan_zia) {
              this.tupian10_da()
            }
          }, 0)
        } else if (res.data.code == 500) {
          this.$message({
            message: '该年份没有盈亏平衡点',
            type: 'warning'
          })
          this.time = ''
        }
      })
    },
    tupian10 () {
      const echarts = require('echarts')
      const myChart = echarts.init(document.getElementById('myCharts10'))
        
      myChart.setOption({
        tooltip: {
          trigger: 'axis',
          axisPointer: { // 坐标轴指示器，坐标轴触发有效
            type: 'shadow' // 默认为直线，可选为：'line' | 'shadow'
          }
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: {
          type: 'category',
          data: this.tupian.x_zhou
        },
        yAxis: {
          type: 'value'
        },
        series: [
          {
            type: 'bar',
            label: {
              show: true,
              position: 'top'
            },
            itemStyle: {
              color: '#FFB466'
            },
            data: this.tupian.zhi
          },
          {
            type: 'line',
            itemStyle: {
              color: '#F16977'
            },
            showSymbol: true,
            symbol: 'circle', // 设定为实心点
            symbolSize: 20, // 设定实心点的大小
            data: this.tupian.zhexian
          }
        ]
      })
    },
    tupian10_da () {
      const echarts = require('echarts')
      const myChart = echarts.init(document.getElementById('myCharts10_da'))
        
      myChart.setOption({
        tooltip: {
          trigger: 'axis',
          axisPointer: { // 坐标轴指示器，坐标轴触发有效
            type: 'shadow' // 默认为直线，可选为：'line' | 'shadow'
          }
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: {
          type: 'category',
          data: this.tupian.x_zhou
        },
        yAxis: {
          type: 'value'
        },
        series: [
          {
            type: 'bar',
            label: {
              show: true,
              position: 'top'
            },
            itemStyle: {
              color: '#FFB466'
            },
            data: this.tupian.zhi
          },
          {
            type: 'line',
            itemStyle: {
              color: '#F16977'
            },
            showSymbol: true,
            symbol: 'circle', // 设定为实心点
            symbolSize: 20, // 设定实心点的大小
            data: this.tupian.zhexian
          }
        ]
      })
    },
    // 点击选择
    dianji_xuanze (index) {
      this.xuanze = index
      if (index == 0) {
        this.xuanze_zhanshi(this.bumen_list)
      } else if (index == 1) {
        this.xuanze_zhanshi(this.chanpin_list)
      } else if (index == 2) {
        this.xuanze_zhanshi(this.renyuan_list)
      }
    },
    xuanze_zhanshi (data) {
      this.tupian.x_zhou = []
      this.tupian.zhi = []
      this.tupian.zhexian = []
      for (let i = 0; i < data.length; i++) {
        this.tupian.x_zhou.push(data[i].month)
        this.tupian.zhi.push(Number(data[i].amt_aim).toFixed(0))
        this.tupian.zhexian.push(Number(data[i].amt_breakeven).toFixed(0))
      }
      setTimeout(() => {
        this.tupian10()
        if (this.quan_zia) {
          this.tupian10_da()
        }
      }, 0)
    },
    // 点击关闭
    dianji_guanbi () {
      // this.$router.push('/aspreackeven')
      // this.quan_zia=''
      // this.$emit('nianfen_fu', '1')
      // sessionStorage.setItem("pc_mlbb_mubiao_biao", '-1');
      if (this.biaoge_nei) {
        this.quan_zia = false
        this.you_xiaoping = false
      } else {
        this.quan_zia = false
      }
      this.xuanze = 0
      // this.yierji_panduan2_da=true
    },
    biaoge_dakai () {
      this.you_xiaoping = false
    },
    dakai_quanping (data) {
      console.log(data)
      this.you_xiaoping = true
      this.time = data.time
      this.quan_zia = data.chutu
      this.biaoge_nei = data.biaoge_nei
      this.chaxun_jiekou()
      // if(data.chutu){
      // this.nianfen_zhi()
      // this.nianfen_zhi2()
      // }else{
      //     // this.nianfen_zhi2()
      // }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.figuresix {
    height:100%;
    width:100%;
    // position: relative;
    .xiaoping{
        height:100%;
        width:100%;
        .xuanze_nianfen{
            padding-top:0.18rem;
            margin-left: 0.16rem;
            margin-right: 0.16rem;
            display: flex;
            justify-content: space-between;
            align-items: center;
            p{
                font-size: 0.12rem;
                color:#4C4A4D;
                display: flex;
                align-items: center;
            }
            div{
                display: flex;
                align-items: center;
                p{
                    display: flex;
                    align-items: center;
                    img{
                        height:0.17rem;
                        width:0.17rem;
                        margin-right: 0.07rem;
                    }
                    font-size: 0.12rem;
                    color:#4C4A4D;
                    margin-left: 0.27rem;
                    &:nth-child(1){
                        margin-left: 0;
                    }
                    cursor: pointer;
                }
            }
            .xuanze_nianfen_right{
                span{
                    display: inline-block;
                    height:0.08rem;
                    width: 0.08rem;
                    &:nth-child(1){
                        background:#FFB466;
                    }
                    &:nth-child(2){
                        background:#F16977;
                    }
                }
            }
        }
        .table_con{
            width:95%;
            margin: 0 auto;
            margin-top: 0.11rem;
        }
    }
    .quanping{
        height:100%;
        width:100%;
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        overflow: auto;
        margin: 0;
        z-index:999;
        background: rgba(0,0,0,0.8);
        .box{
            position: relative;
            height:90%;
            width: 14rem;
            left:50%;
            top:5%;
            margin-left:-8rem;
            background: #F1F3F9;
            .box_text{
                position: absolute;
                font-size: 0.17rem;
                color:#4C4A4D;
                left:5%;
                top:3%;
            }
            .guanbi{
                position: absolute;
                top:-0.2rem;
                right:-0.2rem;
                height:0.6rem;
                width: 0.6rem;
                border-radius: 50%;
                background: #fc6a6a;
                color:#fff;
                text-align: center;
                font-size: 0.3rem;
                line-height: 0.6rem;
                box-shadow:0 0 0.05rem 0.05rem rgba(27, 27, 27, 0.5);
                cursor: pointer;
            }
            .box_con{
                position: relative;
                height:86%;
                width: 90%;
                left:5%;
                top:7%;
                background: #fff;
                .xuanze_nianfen{
                    padding-top:0.18rem;
                    margin-left: 0.16rem;
                    margin-right: 0.16rem;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    p{
                        font-size: 0.12rem;
                        color:#4C4A4D;
                        display: flex;
                        align-items: center;
                    }
                    div{
                        display: flex;
                        align-items: center;
                        p{
                            display: flex;
                            align-items: center;
                            img{
                                height:0.17rem;
                                width:0.17rem;
                                margin-right: 0.07rem;
                            }
                            font-size: 0.12rem;
                            color:#4C4A4D;
                            margin-left: 0.27rem;
                            &:nth-child(1){
                                margin-left: 0;
                            }
                            cursor: pointer;
                        }
                    }
                    .xuanze_nianfen_right{
                        span{
                            display: inline-block;
                            height:0.08rem;
                            width: 0.08rem;
                            &:nth-child(1){
                                background:#FFB466;
                            }
                            &:nth-child(2){
                                background:#F16977;
                            }
                        }
                    }
                }
                .table_con{
                    width:95%;
                    margin: 0 auto;
                    margin-top: 0.11rem;
                }
            }
        }
    }
    @media screen and (max-width: 1600px) {
        .quanping {
            width:1600px;
        }
    }
}
</style>
