<!--
 * @Description: In User Settings Edit
 * @Author: your name
 * @Date: 2019-09-10 09:43:36
 * @LastEditTime: 2019-09-29 14:49:19
 * @LastEditors: Please set LastEditors
 -->
<template>
    <div class="aspreackeven">
        <zongqiu class="xuanfu" v-drag></zongqiu>
        <txa></txa>
        <div class="con">
            <div class="left">
                <div class="tit">
                    <div style="display: flex;align-items: center;justify-content: center;">
                        <img style="height:0.22rem;width:0.22rem;" src="../assets/mubiao.png" alt="">销售目标锁定<i class="el-icon-arrow-down"></i>
                    </div>
                    <ul>
                        <li v-for='(i,index) in text'  :key="index">
                            <router-link :to="i.url"  @click.native="dian_left_list(index)"  :class="path==i.url?'left_biao':''" >{{i.name}}</router-link>
                        </li>
                    </ul>
                    <!-- <p class="p2" @click="jinru_shouye"><img src="../assets/house.png">回到功能区</p> -->
                </div>
                <i class="el-icon-d-arrow-right"></i>
            </div>
            <div class="box">
                <div class="tit">
                    <ul class="tit_ul">
                        <li v-for="(i,index) in tit_list" :key='index' @click="dian_list(index)" :class="{ 'bei':index == inde}">{{i}}</li>
                    </ul>
                </div>
                <div class="title"><img src="../assets/mubiao_laba.png">为确保数据准确设置目标前，请先去后台设置里更新组织架构中<span>人员、部门、核算科目</span>为最新状态。</div>
                <div class="cona">
                    <div class="zhutu" v-if="path=='/aspreackeven'">
                        <div class="tu1">
                            <div class="tu1_tit">
                                <p>盈亏平衡测算表(一)</p>
                                <p>
                                    <span @click="dianji_quanping"><img src='../assets/quanping.png'>全屏</span>
                                    <span v-if="youwu_quanxian" @click="jin_biao1"><img src='../assets/biaoge.png'>表格</span>
                                </p>
                            </div>
                            <div class="tu1_con">
                                <tuyi v-on:nianfen_fu='nianfen_fu' ref='mubiao_tu1'></tuyi>
                            </div>
                        </div>
                        <div class="tu3">
                            <div class="tu3_tit">
                                <p>产品销售规则(三)</p>
                                <p>
                                    <span @click="tu3_dianji_quanping"><img src='../assets/quanping.png'>全屏</span>
                                    <span v-if="youwu_quanxian" @click="jin_biao3"><img src='../assets/biaoge.png'>表格</span>
                                </p>
                            </div>
                            <div class="tu3_con">
                                <tusan @tu3_nianfen="tu3_nianfen" ref='mubiao_tu3'></tusan>
                            </div>
                        </div>
                        <div class="tu2">
                            <div class="tu2_tit">
                                <p>产品销售目标(二)</p>
                                <p>
                                    <span @click="tu2_dianji_quanping"><img src='../assets/quanping.png'>全屏</span>
                                    <span v-if="youwu_quanxian" @click="jin_biao2"><img src='../assets/biaoge.png'>表格</span>
                                </p>
                            </div>
                            <div class="tu2_con">
                                <tuerLeft @tu2_nianfen="tu2_nianfen" ref='mubiao_tu2'></tuerLeft>
                            </div>
                        </div>
                        <div class="tu4">
                            <div class="tu4_tit">
                                <p>部门销售目标(四)</p>
                                <p>
                                    <span @click="tu4_dianji_quanping"><img src='../assets/quanping.png'>全屏</span>
                                    <span v-if="youwu_quanxian" @click="jin_biao4"><img src='../assets/biaoge.png'>表格</span>
                                </p>
                            </div>
                            <div class="tu4_con">
                                <tusi @tu4_nianfen="tu4_nianfen" ref='mubiao_tu4'></tusi>
                            </div>
                        </div>
                        <div class="tu5">
                            <div class="tu5_tit">
                                <p>人员销售目标(五)</p>
                                <p>
                                    <span @click="tu5_dianji_quanping"><img src='../assets/quanping.png'>全屏</span>
                                    <span v-if="youwu_quanxian" @click="jin_biao5"><img src='../assets/biaoge.png'>表格</span>
                                </p>
                            </div>
                            <div class="tu5_con">
                                <tuwu @tu5_nianfen="tu5_nianfen" ref='mubiao_tu5'></tuwu>
                            </div>
                        </div>
                        <div class="tu6">
                            <div class="tu6_tit">
                                <p>销售目标进度(六)</p>
                                <p>
                                    <span @click="tu6_dianji_quanping"><img src='../assets/quanping.png'>全屏</span>
                                    <span v-if="youwu_quanxian" @click="jin_biao6"><img src='../assets/biaoge.png'>表格</span>
                                </p>
                            </div>
                            <div class="tu6_con">
                                <tuliu @tu6_nianfen="tu6_nianfen" ref='mubiao_tu6'></tuliu>
                            </div>
                        </div>
                        <div class="tu7">
                            <div class="tu7_tit">
                                <p>销售责任书(七)</p>
                                <p>
                                    <span @click="tu7_dianji_quanping"><img src='../assets/quanping.png'>全屏</span>
                                    <span v-if="youwu_quanxian" @click="jin_biao7"><img src='../assets/biaoge.png'>表格</span>
                                </p>
                            </div>
                            <div class="tu7_con">
                                <tuqi @tu7_nianfen="tu7_nianfen" ref='mubiao_tu7'></tuqi>
                            </div>
                        </div>
                    </div>
                    <router-view v-if="path!='/aspreackeven'" style="background:#fff;"></router-view>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import tuyi from './Figures/Figureone'
import tuerLeft from './Figures/Figuretwo'
import tusan from './Figures/Figurethree'
import tusi from './Figures/Figurefour'
import tuwu from './Figures/Figurefive'
import tuliu from './Figures/Figuresix'
import tuqi from './Figures/Figureseven'
import zongqiu from '../components/Zonganniu'
import txa from '../components/Txa'
// import tuqi from './Figures/Figureseven'
export default {
  name: 'aspreackeven',
  data () {
    return {
      text: [],
      tit_list: [
        '销售目标'

      ],
      // '产品目标',
      //     '核算目标',
      //     '风控目标',
      //     '成长目标',
      inde: '0',
      left_index: '-1',
      quan: '',
      nianfen: '',
      tu2_nianfen_zhi: '',
      tu2_quan: '',
      tu3_nianfen_zhi: '',
      tu3_quan: '',
      tu4_nianfen_zhi: '',
      tu4_quan: '',
      tu5_nianfen_zhi: '',
      tu5_quan: '',
      tu6_nianfen_zhi: '',
      tu6_quan: '',
      tu7_nianfen_zhi: '',
      tu7_quan: '',
      path: '',
      youwu_quanxian: false
    }
  },
  components: { txa, tuyi, tuerLeft, tusan, tusi, tuwu, tuliu, tuqi, zongqiu },
  created () {
    this.lujing(this.$route.path)
    this.jichu()
    // if (sessionStorage.getItem("pc_mlbb_mubiao_biao") != null&&sessionStorage.getItem("pc_mlbb_mubiao_biao") != undefined&&sessionStorage.getItem("pc_mlbb_mubiao_biao") != '') {
    //     this.left_index = JSON.parse(sessionStorage.getItem("pc_mlbb_mubiao_biao"));
    // }else{
    //     this.left_index='-1'
    // }
  },
  mounted () {
  },
  watch: {
    // $route(){
    //     let date=''
    //     date = this.$route.fullPath
    //     console.log(date)
    //     if(this.$route.fullPath=='/aspreackeven'){
    //         this.left_index='-1'
    //     }
    // },
    $route: {
      handler: function (val, oldVal) {
        this.lujing(val.fullPath)
      },
      // 深度观察监听
      deep: true
    }
  },
  methods: {
    lujing (path) {
      this.path = path
      //
    },
    jichu () {
      console.log(this.$jichuxinxi())
      const date = this.$bendi_naqu_cunchu('mubiao_quanxian_list', '暂时')
      console.log(date)
      if (date != undefined && date != null && date.length != 0) {
        const list = date.map(item => item.read_flag)
        console.log(list)
        if (list.length == 2 || this.$jichuxinxi().ent_info.staff_level == 1) {
          this.text = [
            {
              name: '盈亏平衡测算',
              url: '/tableone'
            },
            {
              name: '产品销售目标',
              url: '/tabletwo'
            },
            {
              name: '产品销售规则',
              url: '/tablethree'
            },
            {
              name: '部门销售目标',
              url: '/tablefour'
            },
            {
              name: '人员销售目标',
              url: '/tablefive'
            },
            {
              name: '销售目标进度',
              url: '/tablesix'
            },
            {
              name: '销售责任书',
              url: '/tableseven'
            }
          ]
          this.youwu_quanxian = true
        } else if (list[0] == 1) {
          this.text = []
          this.youwu_quanxian = false
        } else if (list[0] == 2 || this.$jichuxinxi().ent_info.staff_level == 1) {
          this.text = [
            {
              name: '盈亏平衡测算',
              url: '/tableone'
            },
            {
              name: '产品销售目标',
              url: '/tabletwo'
            },
            {
              name: '产品销售规则',
              url: '/tablethree'
            },
            {
              name: '部门销售目标',
              url: '/tablefour'
            },
            {
              name: '人员销售目标',
              url: '/tablefive'
            },
            {
              name: '销售目标进度',
              url: '/tablesix'
            },
            {
              name: '销售责任书',
              url: '/tableseven'
            }
          ]
          this.youwu_quanxian = true
        }
      } else {
        console.log(this.$jichuxinxi())
        if (this.$jichuxinxi().ent_info.staff_level == 1 || this.$jichuxinxi().ent_info.staff_level == 2) {
          this.text = [
            {
              name: '盈亏平衡测算',
              url: '/tableone'
            },
            {
              name: '产品销售目标',
              url: '/tabletwo'
            },
            {
              name: '产品销售规则',
              url: '/tablethree'
            },
            {
              name: '部门销售目标',
              url: '/tablefour'
            },
            {
              name: '人员销售目标',
              url: '/tablefive'
            },
            {
              name: '销售目标进度',
              url: '/tablesix'
            },
            {
              name: '销售责任书',
              url: '/tableseven'
            }
          ]
          this.youwu_quanxian = true
        } else {
          this.text = []
          this.youwu_quanxian = false
        }
      }
    },
    //   顶部导航
    dian_list (index) {
      this.inde = index
    },
    // 左侧点击
    dian_left_list (index) {
      this.left_index = index
      // sessionStorage.setItem("pc_mlbb_mubiao_biao", JSON.stringify(index));
    },
    // 回到功能区
    jinru_shouye () {
      this.$router.push('/home')
    },
    nianfen_fu: function (val) {
      console.log(val)
      if (val.length == 1) {
        this.quan = ''
      } else {
        this.nianfen = val
      }
    },
    tu2_nianfen: function (val) {
      if (val == 2) {
        this.tu2_quan = ''
      } else {
        this.tu2_nianfen_zhi = val
      }
    },
    tu3_nianfen: function (val) {
      if (val == 2) {
        this.tu3_quan = ''
      } else {
        this.tu3_nianfen_zhi = val
      }
    },
    tu4_nianfen: function (val) {
      if (val == 2) {
        this.tu4_quan = ''
      } else {
        this.tu4_nianfen_zhi = val
      }
    },
    tu5_nianfen: function (val) {
      if (val == 2) {
        this.tu5_quan = ''
      } else {
        this.tu5_nianfen_zhi = val
      }
    },
    tu6_nianfen: function (val) {
      if (val == 2) {
        this.tu6_quan = ''
      } else {
        this.tu6_nianfen_zhi = val
      }
    },
    tu7_nianfen: function (val) {
      if (val == 2) {
        this.tu7_quan = ''
      } else {
        this.tu7_nianfen_zhi = val
      }
    },
    dianji_quanping () {
      if (this.nianfen != '' && this.nianfen != '测算') {
        // this.quan='1'
        this.$refs.mubiao_tu1.dakai_quanping(
          {
            time: this.nianfen,
            chutu: true,
            biaoge_nei: false
          }
        )
      } else {
        this.$message({
          message: '请选择年份，再进入全屏',
          type: 'warning'
        })
      }
    },
    tu2_dianji_quanping () {
      if (this.tu2_nianfen_zhi != '') {
        console.log(1111)
        if (this.tu2_nianfen_zhi != '2') {
          this.$refs.mubiao_tu2.dakai_quanping(
            {
              time: this.tu2_nianfen_zhi,
              chutu: true,
              biaoge_nei: false
            }
          )
        }
      } else {
        this.$message({
          message: '请选择年份，再进入全屏',
          type: 'warning'
        })
      }
    },
    tu3_dianji_quanping () {
      if (this.tu3_nianfen_zhi != '') {
        console.log(1111)
        if (this.tu3_nianfen_zhi != '2') {
          this.$refs.mubiao_tu3.dakai_quanping(
            {
              time: this.tu3_nianfen_zhi,
              chutu: true,
              biaoge_nei: false
            }
          )
        }
      } else {
        this.$message({
          message: '请选择年份，再进入全屏',
          type: 'warning'
        })
      }
    },
    tu4_dianji_quanping () {
      if (this.tu4_nianfen_zhi != '') {
        console.log(1111)
        if (this.tu4_nianfen_zhi != '2') {
          this.$refs.mubiao_tu4.dakai_quanping(
            {
              time: this.tu4_nianfen_zhi,
              chutu: true,
              biaoge_nei: false
            }
          )
        }
      } else {
        this.$message({
          message: '请选择年份，再进入全屏',
          type: 'warning'
        })
      }
    },
    tu5_dianji_quanping () {
      if (this.tu5_nianfen_zhi != '') {
        if (this.tu5_nianfen_zhi != '2') {
          this.$refs.mubiao_tu5.dakai_quanping(
            {
              time: this.tu5_nianfen_zhi,
              chutu: true,
              biaoge_nei: false
            }
          )
        }
      } else {
        this.$message({
          message: '请选择年份，再进入全屏',
          type: 'warning'
        })
      }
    },
    tu6_dianji_quanping () {
      if (this.tu6_nianfen_zhi != '') {
        console.log(1111)
        if (this.tu6_nianfen_zhi != '2') {
          this.$refs.mubiao_tu6.dakai_quanping(
            {
              time: this.tu6_nianfen_zhi,
              chutu: true,
              biaoge_nei: false
            }
          )
        }
      } else {
        this.$message({
          message: '请选择年份，再进入全屏',
          type: 'warning'
        })
      }
    },
    tu7_dianji_quanping () {
      if (this.tu7_nianfen_zhi != '') {
        console.log(1111)
        if (this.tu7_nianfen_zhi != '2') {
          this.$refs.mubiao_tu7.dakai_quanping(
            {
              time: this.tu7_nianfen_zhi,
              chutu: true,
              biaoge_nei: false
            }
          )
        }
      } else {
        this.$message({
          message: '请选择年份，再进入全屏',
          type: 'warning'
        })
      }
    },
    jin_biao1 () {
      this.$router.push('/tableone')
    },
    jin_biao2 () {
      this.$router.push('/tabletwo')
    },
    jin_biao3 () {
      this.$router.push('/tablethree')
    },
    jin_biao4 () {
      this.$router.push('/tablefour')
    },
    jin_biao5 () {
      this.$router.push('/tablefive')
    },
    jin_biao6 () {
      this.$router.push('/tablesix')
    },
    jin_biao7 () {
      this.$router.push('/tableseven')
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.aspreackeven {
    background:#F4F7FD;
    min-height: 100vh;
    .xuanfu{
        position: absolute;
        z-index: 100;
        top:4.3rem;
  		left:1.5rem;
    }
    .con{
        display: flex;
        justify-content: left;
        .left{
            background: #fff;
            position: relative;
            position: fixed;
            top: 0;
            bottom: 0;
            left: 0;
            z-index: 111;
            width:0.4rem;
            transition: width 1s;
            -webkit-transition: width 1s; /* Safari */
            .tit{
                display: none;
                div{
                    height:0.8rem;
                    background:#9a86db;
                    font-size: 0.22rem;
                    color:#FFFFFF;
                    text-align: center;
                    line-height: 0.8rem;
                }
                ul{
                    li{
                        padding: 0.18rem 0;
                        border-bottom: 0.01rem solid #EEEEEE;
                        cursor: pointer;
                        a{
                            text-align: center;
                            display: block;
                            line-height: 0.41rem;
                            font-size: 0.16rem;
                            color:#888;
                        }
                        .left_biao{
                            color:#9a86db;
                            border-right:0.02rem solid #9a86db;
                        }
                    }

                }
                .p2{
                    display: flex;
                    color:#888888;
                    font-size: 0.14rem;
                    height:0.46rem;
                    width:2.05rem;
                    justify-content: center;
                    align-items: center;
                    border:0.01rem solid #888;
                    border-radius: 0.04rem;
                    position: absolute;
                    bottom: 0.3rem;
                    left:0.27rem;
                    cursor: pointer;
                    img{
                        margin-right: 0.06rem;
                        height:13.0.06rem;
                        width:13.0.06rem;
                    }
                }
            }

            &:hover{
                width:2.3rem;
                .tit{
                    display: block;
                }
                i{
                    display: none;
                }
            }
            i{
                position: absolute;
                display: block;
                font-size: 0.18rem;
                top:50%;
                left: 50%;
                margin-top: -0.18rem;
                margin-left: -0.09rem;
            }
        }
        .box{
            width:100%;
            min-height: 100%;
            margin-left:0.4rem;
            background:#F4F7FD;
            ul{
                display: flex;
                align-items: center;
                padding-left: 1.15rem;
                background: #fff;
                position: fixed;
                top: 0;
                width: 100%;
                z-index: 99;
                li{
                    height:0.78rem;
                    line-height: 0.78rem;
                    min-width:0.72rem;
                    margin-right: 0.8rem;
                    color:#1A2533;
                    font-size: 0.18rem;
                    cursor: pointer;
                }
                .bei{
                    color: #9a86db;
                    border-bottom: 0.02rem solid #9a86db;
                }
            }
            .title{
                height: 0.37rem;
                margin-top: 0.78rem;
                background: #FFF6ED;
                color:#979AA1;
                font-size: 0.14rem;
                line-height: 0.37rem;
                padding: 0 0.17rem;
                display: flex;
                align-items: center;
                span{
                    color:#2E7BFC;
                    cursor: pointer;
                }
                img{
                    height:15.0.03rem;
                    width:0.17rem;
                    margin-right: 0.1rem;
                }
            }
            .cona{
                margin: 0.2rem 0.4rem;
                .zhutu{
                    display: flex;
                    flex-wrap: wrap;
                    .tu1{
                        width:8rem;
                        height:4.28rem;
                        box-shadow:0 0 0.05rem 0.05rem rgba(217,217,217,0.5);
                        border-radius: 0.04rem;
                        padding: 0.2rem;
                        background: #F8FAFD;
                        margin-right: 1rem;
                        .tu1_tit{
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                            margin-bottom: 20.0.05rem;
                            P{
                                &:nth-child(1){
                                    font-size: 0.14rem;
                                    color:#4C4A4D;
                                }
                                &:nth-child(2){
                                    span{
                                        font-size: 0.1rem;
                                        color:#4C4A4D;
                                        margin-left: 0.23rem;
                                        display: inline-block;
                                        cursor: pointer;
                                        img{
                                            height:0.12rem;
                                            width:0.12rem;
                                            margin-right: 0.03rem;
                                        }
                                    }
                                }
                            }
                        }
                        .tu1_con{
                            height:3.88rem;
                            width:100%;
                            background:#fff;
                            border-radius: 0.04rem;
                        }
                    }
                    .tu3{
                        width:8rem;
                        height:4.28rem;
                        box-shadow:0 0 0.05rem 0.05rem rgba(217,217,217,0.5);
                        border-radius: 0.04rem;
                        padding: 0.2rem;
                        background: #F8FAFD;
                        .tu3_tit{
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                            margin-bottom: 20.0.05rem;
                            P{
                                &:nth-child(1){
                                    font-size: 0.14rem;
                                    color:#4C4A4D;
                                }
                                &:nth-child(2){
                                    display: flex;
                                    align-items: center;
                                    span{
                                        font-size: 0.1rem;
                                        color:#4C4A4D;
                                        margin-left: 0.23rem;
                                        display: inline-block;
                                        cursor: pointer;
                                        img{
                                            height:0.12rem;
                                            width:0.12rem;
                                            margin-right: 0.03rem;
                                        }
                                    }
                                }
                            }
                        }
                        .tu3_con{
                            height:3.88rem;
                            width:100%;
                            background:#fff;
                            border-radius: 0.04rem;
                        }
                    }
                    .tu2{
                        width:98%;
                        height:4.28rem;
                        box-shadow:0 0 0.05rem 0.05rem rgba(217,217,217,0.5);
                        border-radius: 0.04rem;
                        padding: 0.2rem;
                        background: #F8FAFD;
                        margin-top:0.4rem;
                        margin-bottom:0.4rem;
                        .tu2_tit{
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                            margin-bottom: 20.0.05rem;
                            P{
                                &:nth-child(1){
                                    font-size: 0.14rem;
                                    color:#4C4A4D;
                                }
                                &:nth-child(2){
                                    span{
                                        font-size: 0.1rem;
                                        color:#4C4A4D;
                                        margin-left: 0.23rem;
                                        display: inline-block;
                                        cursor: pointer;
                                        img{
                                            height:0.12rem;
                                            width:0.12rem;
                                            margin-right: 0.03rem;
                                        }
                                    }
                                }
                            }
                        }
                        .tu2_con{
                            width:100%;
                        }
                    }
                    .tu4{
                        width:8.8rem;
                        height:4.28rem;
                        box-shadow:0 0 0.05rem 0.05rem rgba(217,217,217,0.5);
                        border-radius: 0.04rem;
                        padding: 0.2rem;
                        background: #F8FAFD;
                        .tu4_tit{
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                            margin-bottom: 20.0.05rem;
                            P{
                                &:nth-child(1){
                                    font-size: 0.14rem;
                                    color:#4C4A4D;
                                }
                                &:nth-child(2){
                                    span{
                                        font-size: 0.1rem;
                                        color:#4C4A4D;
                                        margin-left: 0.23rem;
                                        display: inline-block;
                                        cursor: pointer;
                                        img{
                                            height:0.12rem;
                                            width:0.12rem;
                                            margin-right: 0.03rem;
                                        }
                                    }
                                }
                            }
                        }
                        .tu4_con{
                            height:3.88rem;
                            width:100%;
                            background:#fff;
                            border-radius: 0.04rem;
                        }
                    }
                    .tu5{
                        width:8rem;
                        height:4.28rem;
                        box-shadow:0 0 0.05rem 0.05rem rgba(217,217,217,0.5);
                        border-radius: 0.04rem;
                        padding: 0.2rem;
                        background: #F8FAFD;
                        margin-left: 0.1rem;
                        .tu5_tit{
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                            margin-bottom: 20.0.05rem;
                            P{
                                &:nth-child(1){
                                    font-size: 0.14rem;
                                    color:#4C4A4D;
                                }
                                &:nth-child(2){
                                    span{
                                        font-size: 0.1rem;
                                        color:#4C4A4D;
                                        margin-left: 0.23rem;
                                        display: inline-block;
                                        cursor: pointer;
                                        img{
                                            height:0.12rem;
                                            width:0.12rem;
                                            margin-right: 0.03rem;
                                        }
                                    }
                                }
                            }
                        }
                        .tu5_con{
                            height:3.88rem;
                            width:100%;
                            background:#fff;
                            border-radius: 0.04rem;
                        }
                    }
                    .tu6{
                        width:98%;
                        height:4.28rem;
                        box-shadow:0 0 0.05rem 0.05rem rgba(217,217,217,0.5);
                        border-radius: 0.04rem;
                        padding: 0.2rem;
                        background: #F8FAFD;
                        margin-top:0.4rem;
                        margin-bottom:0.4rem;
                        .tu6_tit{
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                            margin-bottom: 20.0.05rem;
                            P{
                                &:nth-child(1){
                                    font-size: 0.14rem;
                                    color:#4C4A4D;
                                }
                                &:nth-child(2){
                                    span{
                                        font-size: 0.1rem;
                                        color:#4C4A4D;
                                        margin-left: 0.23rem;
                                        display: inline-block;
                                        cursor: pointer;
                                        img{
                                            height:0.12rem;
                                            width:0.12rem;
                                            margin-right: 0.03rem;
                                        }
                                    }
                                }
                            }
                        }
                        .tu6_con{
                            height:3.88rem;
                            width:100%;
                            background:#fff;
                            border-radius: 0.04rem;
                        }
                    }
                    .tu7{
                        width:98%;
                        height:4.28rem;
                        box-shadow:0 0 0.05rem 0.05rem rgba(217,217,217,0.5);
                        border-radius: 0.04rem;
                        padding: 0.2rem;
                        background: #F8FAFD;
                        margin-top:0.4rem;
                        margin-bottom:0.4rem;
                        .tu7_tit{
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                            margin-bottom: 20.0.05rem;
                            P{
                                &:nth-child(1){
                                    font-size: 0.14rem;
                                    color:#4C4A4D;
                                }
                                &:nth-child(2){
                                    span{
                                        font-size: 0.1rem;
                                        color:#4C4A4D;
                                        margin-left: 0.23rem;
                                        display: inline-block;
                                        cursor: pointer;
                                        img{
                                            height:0.12rem;
                                            width:0.12rem;
                                            margin-right: 0.03rem;
                                        }
                                    }
                                }
                            }
                        }
                        .tu7_con{
                            width:100%;
                        }
                    }
                    @media screen and (max-width: 15rem) {
                        .tu2 {
                            width:11rem;
                        }
                        .tu7 {
                            width:11rem;
                        }
                    }
                    @media screen and (max-width: 17rem) {
                        .tu3 {
                            margin-top: 0.4rem;
                        }
                        .tu5 {
                            margin-top: 0.4rem;
                            margin-left: 0;
                        }
                    }
                }
            }
        }
        @media screen and (max-width: 1220px) {
            .box{
                width:1220px;
            }
        }
    }
}
@media screen and (max-width: 1220px) {
  .aspreackeven {
    width:1220px;
  }
}
@media screen and (max-height: 716px) {
  .left {
    height:716px;
  }
}
</style>
